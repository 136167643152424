@import '../../../../styles/variables';
@import '../../../../styles/mixin';

.search {

  background: radial-gradient(91.37% 598.89% at 96.69% 9.66%, #2E3BEF 0%, rgba(128, 134, 229, 1) 0.01%, #2E3BEF 54.69%, rgba(129, 136, 228, 1) 100%);
  border-radius: 32px;
  margin-top: 100px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  img:first-child {
    margin-right: 8px;
  }

  .input-wrapper{
    display: flex;
    max-width: 776px;
    width: 100%;
  }
  h2 {
    color: $white;
    @include fluid-font(32px,48px,40px,72px);
    font-weight: 700;

    text-align: left;

  }
}
@media (max-width:879px){
  .search{
    margin-top: 30px;
  }
  

}
@media (max-width:600px){
  .search{
    margin-top: 20px;
  }
  

}
@media (max-width:405px){
  .search{
    margin-top: 20px;
    input{
      width: 50px;
    }
  }
}
