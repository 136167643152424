@import "../../../../styles/variables";

.news {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .news-items {
    display: flex;
    gap: 32px;
  }

  .slide-items {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > p {
      color: $charcoal-blue;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      span {
        font-weight: 600;
      }
    }

    .slide-btn{
      margin-top: 8px;
      display: flex;
    }
    .left,.right{
      cursor: pointer;
      padding: 10px 16px;
      gap: 4px;
      background: $very-light-gray;
      display: flex;
    }
    .right {
      border-left: 1px solid $charcoal-blue;
    }
  }
}

@media (max-width:880px){
  .news {
 
  
    .news-items {
      display:block;
      
    }
}
}