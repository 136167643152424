@import "../../styles/variables";
@import "../../styles/mixin";

.table {
  width: 100%;

  thead {
    @include fluid-font(14px, 18px, 18px, 18px);
    color: $slate-gray;
    font-weight: 400;
    text-align: left;


    th {
      padding-bottom: 16px;
    }

  }

  tbody {
    @include fluid-font(14px, 18px, 18px, 18px);
    td {
      vertical-align: middle;

      & * {
        height: 100%;
        display: flex;
        align-items: center;
      }

      height: 88px;
      border-bottom: 1px solid #DADBEB
    }
  }
}
