@import "../../styles/variables";
@import "../../styles/mixin";

.trade-history-page {
  max-width: 864px;

  h2 {
    @include fluid-font(24px, 32px, 32px, 32px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 22px;
  }

  .history-item {
    border: 1px solid $pale-gray;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    background-color: white;

    .history-item-details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //justify-content: space-between;
      //max-height: 192px;
      row-gap: 24px;
      column-gap: 32px;

      div {

        width: 172px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        strong {
          color: $slate-gray;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;

        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;

        }
      }

      .closing-pnl {
        color: $forest-green;
      }
    }
  }

}

.dark{
  .trade-history-page-wrapper{
    background: $dark-bg;
    .trade-history-page{
      h2{
        color: rgba(157, 157, 161, 1);
      }
      .history-item{
        border:1px rgba(32, 37, 54, 1) solid;
        background: linear-gradient(90deg, #0F101D 0%, #1B2133 100.03%);
        strong{
          color: white;
        }
        .grayy{
            color:rgba(157, 157, 161, 1);
        }
        .green{
          color:  rgba(14, 163, 113, 1);

        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .trade-history-page {
    .history-item {
      .history-item-details {
        justify-content: start;
      }
    }
  }
}

@media (max-width: 880px) {
  .trade-history-page {
    .history-item {
      .history-item-details {
        justify-content: start;

        div {
          max-width: 150px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .trade-history-page {
    .history-item {
      .history-item-details {
        justify-content: start;

        div {
          max-width: 120px;
        }
      }
    }
  }
}
