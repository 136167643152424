@import "../../../styles/variables";

.pricing-modal {

  max-width: 958px;

  h1 {

    font-size: 32px;
    font-weight: 700;
    line-height: 22.5px;
    text-align: center;

    margin-bottom: 42px;

    span {
      color: $royal-blue;
    }

  }

  .link-btn {
    all: unset;
    cursor: pointer;
    margin-top: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 16px;
    }
  }


  .pricing-plan {
    overflow-y: scroll;
    display: flex;
    gap: 24px;
    /* Убираем скроллбар для веб-браузеров на основе WebKit */


    .plus {
      position: relative;
      border: 1px solid $royal-blue;
      border-radius: 16px;
      padding: 32px;

      flex-basis: 50%;

      li::before {
        content: "";
        position: absolute;
        left: 0px;
        width: 20px;
        height: 20px;

        background-image: url("../../../assets/icons/check.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
      }

      .status {
        background-color: $royal-blue;
        padding: 8px 16px;
        border-radius: 0 16px 0 16px;
        position: absolute;
        right: 0;
        top: 0;

        box-shadow: 0px 2px 16px 0px #88899040;

        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
      }

      h2 {
        color: $royal-blue;

        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;

      }
    }

    li {
      padding-left: 36px;
      position: relative;
    }

    .status-description {
      color: $slate-gray;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      margin-top: 8px;
      margin-bottom: 16px;
    }

    .price {

      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;

      margin-bottom: 32px;
    }

    .plan-list {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-bottom: 32px;
      border-top: 1px solid $pale-gray;
      padding-top: 32px;

      span {

        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;

      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;

      }

    }

    .basic {
      border: 1px solid $pale-gray;
      border-radius: 16px;
      padding: 32px;

      flex-basis: 50%;

      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;

      }

      li::before {
        content: "";
        position: absolute;
        left: 0px;
        width: 20px;
        height: 20px;

        background-image: url("../../../assets/icons/checkBlack.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
      }
    }
  }

}

@media (max-width: 880px) {
  .pricing-modal {

    .pricing-plan {
      display: flex;
      flex-direction: column;
    }
  }
  .pricing-modal-wrapper {
    .content {
      overflow-y: scroll;
      border: none;
    }
  }
}
