@import "../../styles/variables";

.burger-menu {
  .burger-menu-theme{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .burger-menu-btn {
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
      transition: all 1s;
      background: $black;
      width: 24px;
      height: 2px;
    }

    &.active {
      gap: 0;

      span:nth-child(1) {
        transform: rotate(45deg);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:nth-child(3) {
        margin-top: -4px;
        transform: rotate(-45deg);
      }
    }
  }

  .burger-menu-content {
    transition: all .3s;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $white;
    width: 100%;
    height: 100vh;
    left: -100%;
    z-index: 99999;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    &.active{
      left: 0;
    }

    .burger-menu-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .theme-toggle{
    margin-top: 16px;
  }
}
.dark{
  .burger-menu-btn{
    span {
      background: $white;
    }
  }
  .burger-menu-content{
    background: $dark-bg;
    color: white;


    .sidebar-item{
      p{
        color: white;
      }
    }

  }
}
