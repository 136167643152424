@import "../../styles/variables";
@import "../../styles/mixin";

.satellites-page {
  margin-top: 64px;

  .balance {
    p {
      @include fluid-font(14px,20px,18px,20px);
      color: $slate-gray;
      font-weight: 400;
      text-align: left;
      margin-bottom: 24px;
    }

    h2 {
      @include fluid-font(32px,56px,40px,56px);
      font-weight: 600;
      text-align: left;

    }

    margin-bottom: 56px;
  }

  button {
    height: 40px !important;
  }

  .up {
    color: $forest-green;
    margin-left: 8px;
  }

  .down {
    color: $crimson-red;
    margin-left: 8px;
  }

}
.dark{
  .satellities-page-wrapper{
    background: $dark-bg;
    .satellites-page {
      .balance {
        p,h2 {
            color: white;
        } 
    }
    .table{
      thead{
        color: rgba(157, 157, 161, 1);
      }
     
        
    }
    tbody{
      tr{
        color: white;
      }
    }
    button {
      background: linear-gradient(90deg, #324ADB 0%, #1D54D4 40%, #0C2CAA 100%);
      color: white;

    }
  }
}
}

@media (max-width: 880px) {
  .satellites-page {
    table {
      display: flex;
      flex-direction: column;
    }

    thead {
      width: 100%;
      display: flex;

      & tr > *:last-child {
        display: none;
      }

      tr {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 0px;
        justify-self: center;
      }

      & > tr > * {
        color: #9D9DA1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        vertical-align: center;
      }
    }

    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;

      tr {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 0px;

      }
      & > tr> *:last-child{
        grid-area: 2 / 1 / 3 / 6;
        border-bottom: none;
      }
      & > tr > * {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }
  }
}

