@import "../../../../styles/variables";
@import "../../../../styles/mixin";

.start {
  position: relative;
  overflow: hidden;
  height: 815px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .top {
    z-index: 2;
    padding-top: 80px;
    height: 277px;
    width: 100%;
    background: #0000004D;
    backdrop-filter: blur(10px);

    .h1 {
      color: $white;
      margin-top: 36px;
      @include fluid-font(40px, 80px, 48px, 80px);
      font-weight: 700;
      text-align: left;

    }

    span {
      color: $royal-blue;
    }

  }

  .join-part {
    width: fit-content;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(0deg, #2E3BEF, #2E3BEF);
  }

  .join-part-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px 32px;
    gap: 24px;
    background: radial-gradient(91.37% 598.89% at 96.69% 9.66%, #2E3BEF 0%, rgba(128, 134, 229, 0.79) 0.01%, #2E3BEF 0.02%, rgba(129, 136, 228, 0.8) 100%);

    h3 {
      color: $white;
      @include fluid-font(32px, 24px, 40px, 36px);
      font-weight: 700;
      text-align: left;

    }
  }


}
