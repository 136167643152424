@import "../../styles/variables";
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  .header-flex{
    display: none;
  }
}

.header-flex{
  display: flex;
  height: 80px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 20px;
}
.content-flex{
  height: 100%;
  display: flex;
}
.page-wrapper{
  flex: 1;
  padding: 64px 32px;

  overflow-y: scroll;
  max-height: calc(100vh - 80px);
}
.dark {
  .page {
    background: $dark-bg;
  }
}
@media (max-width: 880px) {
  .content-flex{
    .sidebar{
      display: none;
    }
  }
  .page{
    .header-flex{
      display: flex;
    }
  }
}
