@import "../../styles/variables";
@import "../../styles/mixin";

.pricing-page {

  padding-top: 64px;

  h1 {
    @include fluid-font(24px,56px,32px,56px);
    font-weight: 700;
    text-align: center;

    margin-bottom: 48px;

    span {
      color: $royal-blue;
    }

  }

  .link-btn {
    cursor: pointer;
    margin-top: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 16px;
    }
  }

  .p-40 {
    padding: 40px;
  }

  .pricing-plan {
    display: flex;
    gap: 24px;

    .plus {
      position: relative;
      border: 1px solid $pale-gray;
      border-radius: 16px;


      flex-basis: 50%;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
      }

      li::before {
        content: "";
        position: absolute;
        left: 0px;
        width: 20px;
        height: 20px;

        background-image: url("../../assets/icons/checkBlack.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
      }

      .status {
        background-color: $soft-sky-blue;
        padding: 8px 16px;
        border-radius: 16px;


        //box-shadow: 0px 2px 16px 0px #88899040;

        color: $royal-blue;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
      }

      h2 {
        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;

      }
    }

    li {
      padding-left: 36px;
      position: relative;
    }

    .status-description {
      color: $slate-gray;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      margin-top: 8px;
      margin-bottom: 16px;
    }

    .price {

      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;

      margin-bottom: 32px;
    }

    .plan-details {
      border-radius: 24px;
      border-top: 1px solid $pale-gray;
    }

    .plan-list {
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding-bottom: 32px;

      padding-top: 32px;
      border-bottom: 1px solid $pale-gray;
      span {

        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;

      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;

      }

    }

    .basic {
      border: 1px solid $pale-gray;
      border-radius: 16px;
      padding: 32px;

      flex-basis: 50%;

      h2 {
        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;

      }

      li::before {
        content: "";
        position: absolute;
        left: 0px;
        width: 20px;
        height: 20px;

        background-image: url("../../assets/icons/checkBlack.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
      }
    }
  }

}
@media (max-width:1180px){
  .pricing-page{
    .pricing-plan {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
.dark{
  .pricing-page-wrapper{
    background:$dark-bg ;
  }
  .pricing-page{
  .plus{
    background: linear-gradient(90deg, #0F101D 0%, #1B2133 100.03%);
    border: 1px solid $midnight-gray;
    p{
      color: $white;
    }
    h2{
      color: $white;
    }
    .status{
      background-color: $midnight-gray;
    }
  }
  .plan-details {
    border-radius: 24px;
    border-top: 1px solid $midnight-gray;
  }
  h1{
    color: $white;
  }
  .plus,
  .basic {
    li::before {
      background-image: url("../../assets/icons/check-dark.svg");
    }
    .plan-list{
      border-bottom: 1px solid $midnight-gray;
    }
}

  }
}