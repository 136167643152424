@import "../../../styles/variables";

.thank-you-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 370px;
  gap: 32px;

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 22.5px;
    text-align: center;

  }

  p {
    color: $slate-gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

  }
}

.dark {
  .thank-you-modal-wrapper {
    .content {
      background: linear-gradient(90deg, #0F101D 0%, #1B2133 100.03%);

    }
  }
}
