@import "../../styles/variables";

.sidebar {
  max-width: 384px;
  padding: 64px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .sidebar-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;

    }
  }

  .sidebar-item {
    cursor: pointer;
    height: 24px;
    display: flex;
    align-items: center;

    p {
      color: $slate-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

    }

    .icon {

      padding: 0 8px;
    }
  }

  .sidebar-item.active .icon {
    box-sizing: border-box;
    border-left: 2px solid $royal-blue;
  }

  .sidebar-list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    .divider {
      background: $gentle-gray-blue;
      height: 2px;
      width: 100%;
      margin: 8px 0;
    }
  }

  .sidebar-footer {

    padding-bottom: 40px;
    align-items: flex-end;
    display: flex;
    gap: 27px;
    color: $crimson-red;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    justify-content: left;
    & > * {
      height: 24px;
    }

  }
  &.isBurger{
    padding: 0;
    max-width: 100%;
  }
}
.sidebar.closed {
  max-width: 108px;

  .sidebar-header {
    justify-content: center;
  }
  .sidebar-footer {
    justify-content: center;
  }
}
.dark-sidebar{height: 100%}
.dark{

  .dark-sidebar{
    background: $dark-bg;
    color: white;
    .sidebar-item{
      p{
        color: white;
      }
    }
  }
}
