$white: #fff;
$black: #000;
$dark-navy: #111827;
$charcoal-blue: #374151;
$almost-black: #1E1E1E;

$light-gray: #E5E7EB;
$very-light-gray: #F3F4F6;
$light-fog: #F9FAFB;
$soft-gray: #D1D5DB;
$mint-gray: #B4B8BE;
$dusty-gray: #9CA3AF;
$gray-fog: #6B7280;
$dusty-gray:#9D9DA1;
$slate-gray: #4B5563;
$pale-gray: #D9D9D9;
$subtle-gray: #E8E8E8;

$gentle-gray-blue: #E6EBF1;
$soft-sky-blue: #D9E5FF;
$electric-blue: #1C64F2;
$bright-blue: #3F83F8;
$royal-blue: #1A56DB;
$modal-wrapper-overlay: #26242480;
$crimson-red: #C93434;
$forest-green: #3F8B4D;

//DARK THEME
$midnight-navy: #0C0E1D;
$dark-bg:#060819;
$midnight-gray:#202536;
