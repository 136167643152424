@import "../../../../styles/variables";
@import "../../../../styles/mixin";
.details-part{
  margin-top: 64px;
  display: flex;
  gap: 32px;
  .left {
    flex: 1;
    border-radius: 16px;
    overflow: hidden;
  }
  .right {
    flex: 1;
    background: $electric-blue;
    padding: 24px;
    border-radius: 16px;

    h2 {
      color: $white;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      text-align: left;
      margin-bottom: 24px;

    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: left;
      gap: 12px;
      .button-secondary {
        color: $black;
        background: $very-light-gray;
        border-radius: 8px;
        padding: 16px 20px;
        width: fit-content!important;
      }
      .form-field {
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
          color: $white;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          text-align: left;

        }
      }
    }

  }
}

@media (max-width: 1100px) {
  .details-part {
    display: block;
    .left,
    .right {
      width: 100%;
      margin-bottom: 24px;
      .form {
        .button-secondary {
          width:100% !important;
        }
      }
    }
    .left {
      height: 558px;
      
    }
  }
}

@media (max-width:600px){
  .details-part {
    display: block;
    .left,
    .right {
      height: 680px;
    }
    .left {
      height: 458px;
      
    }
  }

}
@media (max-width:400px){
  .details-part {
    display: block;
    .left,
    .right {
      height: 678px;
    }
    .left {
      height: 323px;
    }
  }

}
@media (max-width: 390px) {
  .details-part{
    display: block;
    flex-direction: column;
    margin: 0 15px;

    .left{
      img{
        margin-right: 15px;
        padding-top: 20px;
        width: 100%;
        height: 323px;
        object-fit:cover;
        border-radius: 18px;
      }
    }
    .right {
      margin-top: 40px;
    }
  }
}
@media (max-width: 351px) {
  .details-part{
    display: block;
    flex-direction: column;
    margin: 0 15px;

    .left{
      img{
        margin-right: 15px;
        padding-top: 20px;
        width: 100%;
        height: 323px;
        object-fit:cover;
        border-radius: 18px;
      }
    }
    .right {
      margin-top: 40px;
        height: 710px;
        input{
          width: 50px;
        }
        textarea{
          width: 50px;
        }
    }
  }
}
