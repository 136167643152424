@import "../../styles/variables";
.dark{
    .blog-page-wrapper{
        background-color: $dark-bg;

         h1{
          color: $white;
         }

         h2{
            color: $white;
         }
        }
        .news-card {
            border: 1px rgba(32, 37, 54, 1) solid ;
            .content{
                h3{
                    color: white;
                }
                p{
                    color: white;
                }
            }

        }
        .news{
            .slide-items{
                .left,.right{

                    background: none;
            }
            .left{
                border: 1px solid  rgba(157, 157, 161, 1);
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            .right {
                border: 1px solid rgba(157, 157, 161, 1);
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
              p{
                color: white;
              }
              span{
                color: rgba(157, 157, 161, 1);
              }
        }}

}
