@import "../../styles/variables";
.dark{
    .contact-page-wrapper{
        background: $dark-bg;

        .header-part{
            .left{
                 h2 {
                    color: white;
                }
                p{
                   color: rgba(255, 255, 255, 1);
                }
            }
            .right{
                .item{
                    h3{
                        color: white;
                    }
                    p{
                        color: white;
                    }
                }
            }
        }
        .details-part{
            .right {
                border: 1px rgba(32, 37, 54, 1) solid;  
                background: $dark-bg;
                .form{
                    
                    .form-field{
                       
                        p{
                            color:rgba(255, 255, 255, 1);
                        }
                        .control-input{
                            background: linear-gradient(90deg, rgba(15, 16, 29, 1) 0%, rgba(27, 33, 51, 1) 100%);


                            border: 1px rgba(32, 37, 54, 1) solid;
                        }
                        input{
                            
                            background: linear-gradient(90deg, rgba(15, 16, 29, 1) 0%, rgba(27, 33, 51, 1) 100%);


                            color: rgba(157, 157, 161, 1);
                        }
                        textarea{
                            
                            background: linear-gradient(90deg, rgba(15, 16, 29, 1) 0%, rgba(27, 33, 51, 1) 100%);

                        }
                        .control-textarea{
                            border: 1px rgba(32, 37, 54, 1) solid;
                            background: linear-gradient(90deg, rgba(15, 16, 29, 1) 0%, rgba(27, 33, 51, 1) 100%);

                        }
                    }
                }
            }
        }
    }
}
