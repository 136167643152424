@import "../../../../styles/variables";

.advantages-list {

  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  h2 {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;

  }

  li {
    border: 1px solid $light-gray;
    border-radius: 24px;
    width: calc((100% - 32px) / 2);

  }

  p {
    margin-top: 20px;
    margin-bottom: 39px;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    color: $slate-gray;
  }

  div {
    margin-top: 25px;
    text-align: center;
  }
}

.advantages {
  margin-top: 100px;
}

@media (max-width: 880px) {
  .advantages-list {
    li {
      min-width: 100%;
    }
  }
}
.dark{
  .advantages-list{
    li{
      background: linear-gradient(90deg, #0F101D 0%, #1B2133 100.03%);
      border: 1px solid $midnight-gray;
    }
  }
}