.personal-info {
  margin-top: 32px;
  display: flex;
  max-width: 1264px;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 32px;
  row-gap: 24px;

  .control-input {
    max-width: 416px;
  }

  .input-wrapper-custom {
    max-width: 416px;
  }

  .control-input-phone {
    max-width: 416px;
  }

  button {
    max-width: 304px;
  }

}

@media (max-width: 880px) {
  .personal-info {
    flex-direction: column;
    max-width: 100%;

    .control-input {
      max-width: 100%;
    }

    .input-wrapper-custom {
      width: 100% !important;
      max-width: 100%;
    }

    .control-input-phone {
      max-width: 100%;
    }

    button {
      max-width: 100%;
    }
  }
}
