@import "../../../../styles/variables";
@import "../../../../styles/mixin";

.news-card {
  width: 360px;
  height: 450px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #0000001A;
  border: 1px solid $light-gray;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .news-img {
    width: 100%;
    height: 234px;

    img {
      width: 100%;
      object-fit: cover;
      max-height: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    button {
      width: fit-content!important;
    }
  }

  h3 {
    color: $dark-navy;
    @include fluid-font(16px,24px,24px,32px);
    font-weight: 700;
    letter-spacing: -0.6000000238418579px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Ограничение на 2 строки */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin-top: 8px;
    color: $charcoal-blue;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Ограничение на 2 строки */
    overflow: hidden;
    text-overflow: ellipsis;

  }
}

@media (max-width:1180px){
  .news-card {
    width: 310px;
  }
}
@media (max-width:1008px){
  .news-card {
    width: 270px;
  }
}
@media (max-width:880px){
  .news-card {
    width: 100%;
    margin-bottom: 20px;

    .news-img {
      width: 100%;
      height: 50%;
  
      img {
        width: 100%;
        object-fit: cover;
        max-height: 100%;
      }
    }


  }
}

@media (max-width:480px){
  .news-card {
   width: 100%;
   .news-img {
    width: 100%;
    height: 234px;
   }
  
  }
}

@media (max-width:340px){
  .news-card {
   width: 290px;
   .news-img {
    width: 359px;
    height: 234px;
   }
  }
}