@import "../../styles/variables";
.home-page{

}
.dark{
    .home-page-wrapper{
        background-color: $dark-bg;
    
         h1{
          color: $white;
         }
         p{
          color: $white;
         }
         h2{
            color: $white;
         }
        }
      
}